import { pl } from 'date-fns/locale';
import { filter, isEmpty, map } from 'lodash';
import { normalizeAmount } from './transformer.util';

export function addBudgetDetailFormat(payload) {
  const { semesterOne, semesterTwo } = payload;
  const semesterOneList = map(filter(semesterOne, data => isEmpty(data.unique_id)), list => ({
    semester: 1,
    code_of_account: list.parameter_code.code,
    title: list.title || '',
    quantity: normalizeAmount(list.quantity),
    price: normalizeAmount(list.price),
    term: normalizeAmount(list.term),
    ypl: normalizeAmount(list.ypl),
    committee: normalizeAmount(list.committee),
    intern: normalizeAmount(list.intern),
    bos: normalizeAmount(list.bos),
    total: normalizeAmount(list.quantity)
    * normalizeAmount(list.price)
    * normalizeAmount(list.term),
    desc: list.desc,
    file: list.file,
  }));
  const semesterTwoList = map(filter(semesterTwo, data => isEmpty(data.unique_id)), list => ({
    semester: 2,
    code_of_account: list.parameter_code.code,
    title: list.title || '',
    quantity: normalizeAmount(list.quantity),
    price: normalizeAmount(list.price),
    term: normalizeAmount(list.term),
    ypl: normalizeAmount(list.ypl),
    committee: normalizeAmount(list.committee),
    intern: normalizeAmount(list.intern),
    bos: normalizeAmount(list.bos),
    total: normalizeAmount(list.quantity)
    * normalizeAmount(list.price)
    * normalizeAmount(list.term),
    desc: list.desc,
    file: list.file,
  }));

  return [...semesterTwoList, ...semesterOneList];
}

export function editBudgetDetailFormat(payload) {
  return [{
    id: payload.id,
    semester: payload.semester,
    code_of_account: payload.parameter_code.code,
    title: payload.title,
    quantity: normalizeAmount(payload.quantity),
    price: normalizeAmount(payload.price),
    term: normalizeAmount(payload.term),
    ypl: normalizeAmount(payload.ypl),
    committee: normalizeAmount(payload.committee),
    intern: normalizeAmount(payload.intern),
    bos: normalizeAmount(payload.bos),
    total: normalizeAmount(payload.quantity)
    * normalizeAmount(payload.price)
    * normalizeAmount(payload.term),
    desc: payload.desc,
    file: payload.file,
  }];
}

export function saveBudgetTransferFormat(payload) {
  return {
    ...(payload.id ? { id: payload.id } : {}),
    head: payload.head.id,
    description: payload.description,
    account: payload.code_of_account.code,
    sources: map(payload.selectedBudget, budget => ({
      budget_detail_unique_id: budget.unique_id,
      relocated_amount: normalizeAmount(budget.amount),
    })),
    recipients: map(payload.rapbuSource, list => ({
      ...(list.id ? { id: list.id } : {}),
      semester: list.semester,
      code_of_account: list.code.code,
      title: list.title,
      quantity: normalizeAmount(list.quantity),
      price: normalizeAmount(list.price),
      term: normalizeAmount(list.term),
      total: normalizeAmount(list.quantity)
      * normalizeAmount(list.price) * normalizeAmount(list.term),
      desc: list.desc,
    })),
  };
}

export function saveManageCashJournalFormat(payload) {
  return {
    ...(payload.id ? { id: payload.id } : {}),
    date: payload.date,
    type: payload.type,
    pos: payload.pos || '',
    unit: (payload.unit) ? payload.unit.id : null,
    submitted_by: payload.submitted_by,
    received_by: payload.received_by,
    tipe: payload.tipe,
    details: {
      standard: map(payload.standard, list => ({
        code_of_account: list.code_of_account.code,
        description: list.description,
        nominal: normalizeAmount(list.nominal),
        tax_type: list.tax_type,
        tax_value: normalizeAmount(list.tax_value),
        npwp: list.npwp,
        name: list.name,
        tax: list.tax,
        budget_details: list.budget_details || [],
      })),
      reconciliation: map(payload.reconciliation, list => ({
        code_of_account: list.code_of_account.code,
        nominal: normalizeAmount(list.nominal),
        description: list.description,
      })),
    },
  };
}

export function saveManageBankJournalFormat(payload) {
  return {
    ...(payload.id ? { id: payload.id } : {}),
    date: payload.date,
    journal_number: '',
    pos: payload.pos,
    type: payload.type,
    unit: (payload.unit) ? payload.unit.id : null,
    reference_number: '',
    counterparty: '',
    tax_number: '',
    tax_value: '',
    bank_account: (payload.bank_account) ? payload.bank_account.id : null,
    submitted_by: payload.submitted_by,
    received_by: payload.received_by,
    tipe: payload.tipe,
    details: {
      standard: map(payload.standard, list => ({
        code_of_account: list.code_of_account.code,
        description: list.description,
        nominal: normalizeAmount(list.nominal),
        tax_type: list.tax_type,
        tax_value: normalizeAmount(list.tax_value),
        npwp: list.npwp,
        name: list.name,
        budget_details: list.budget_details || [],
      })),
      reconciliation: map(payload.reconciliation, list => ({
        code_of_account: list.code_of_account.code,
        nominal: normalizeAmount(list.nominal),
        description: list.description,
      })),
    },
  };
}

export function managePaymentJournalFormat(payload) {
  return {
    ...(payload.id ? { id: payload.id } : {}),
    date: payload.date,
    journal_number: payload.journal_number,
    va_code: payload.va_code,
    mmyy: payload.mmyy,
    payment_type: payload.payment_type,
    nominal: normalizeAmount(payload.nominal),
  };
}

export function manageAdjustmentJournalFormat(payload) {
  return {
    ...(payload.id ? { id: payload.id } : {}),
    journal_number: payload.journal_number,
    date: payload.date,
    details: map(payload.details, detail => ({
      code_of_account: detail.code_of_account.code,
      description: detail.description,
      nominal: normalizeAmount(detail.nominal),
      isCredit: detail.isCredit,
    })),
  };
}

export function manageClosingJournalFormat(payload) {
  return {
    ...(payload.id ? { id: payload.id } : {}),
    journal_number: payload.journal_number,
    date: payload.date,
    details: map(payload.details, detail => ({
      code_of_account: detail.code_of_account.code,
      description: detail.description,
      debit: normalizeAmount(detail.debit),
      credit: normalizeAmount(detail.credit),
    })),
  };
}

export function manageNonKasJournalFormat(payload) {
  return {
    ...(payload.id ? { id: payload.id } : {}),
    journal_number: payload.journal_number,
    date: payload.date,
    details: map(payload.details, detail => ({
      code_of_account: detail.code_of_account.code,
      description: detail.description,
      nominal: normalizeAmount(detail.nominal),
      isCredit: detail.isCredit,
    })),
  };
}

export function manageGeneralFormat(payload) {
  return {
    ...(payload.id ? { id: payload.id } : {}),
    journal_number: payload.journal_number,
    date: payload.date,
    details: map(payload.details, detail => ({
      code_of_account: detail.code_of_account.code,
      description: detail.description,
      debit: normalizeAmount(detail.debit),
      credit: normalizeAmount(detail.credit),
    })),
  };
}

export function manageInformationLetterFormat(payload, isEdit) {
  console.log(payload)
  return {
    ...(isEdit ? { id: payload.id, no_sk : payload.no_sk } : {}),
    nip: payload.nip,
    id_sk_descriptions: payload.id_sk_descriptions,
    keterangan_sk : payload.keterangan_sk,
    nama_ketua_yayasan : payload.nama_ketua_yayasan,
    tanggal_pembuatan_sk : payload.tanggal_pembuatan_sk,
    pangkat_golongan : payload.pangkat_golongan,
    jabatan : payload.jabatan,
    status_kepegawaian : payload.status_kepegawaian,
    gaji_pokok : Number(payload.gaji_pokok),
    tanggal_berlaku_sk : payload.tanggal_berlaku_sk,
    nama_tabel_gaji : payload.nama_tabel_gaji,
    lama_bekerja_tahun : Number(payload.lama_bekerja_tahun),
    lama_bekerja_bulan : Number(payload.lama_bekerja_bulan),
    keterangan : payload.keterangan,
    created_by: payload.created_by,
    tembusan : JSON.stringify(payload.tembusan),
    flag: 1,
  };
}

export function manageStaffFormat(payload, isEdit) {
  console.log(payload )
  return {
    ...(isEdit ? { nip: payload.nip } : {}),
    nip:payload.nip,
    no_g:payload.no_g,
    nama_lengkap: payload.nama_lengkap,
    nama_panggilan: payload.nama_panggilan,
    email : payload.email,
    tempat_lahir: payload.tempat_lahir,
    tanggal_lahir: payload.tanggal_lahir,
    id_absensi_kartu_fingerprint: payload.id_absensi_kartu_fingerprint,
    jenis_kelamin: payload.jenis_kelamin,
    tinggi: payload.tinggi,
    berat: payload.berat,
    alamat_tempat_tinggal: payload.alamat_tempat_tinggal,
    rt: payload.rt,
    rw: payload.rw,
    kelurahan: payload.kelurahan,
    kecamatan: payload.kecamatan,
    kotamadya_kabupaten: payload.kotamadya_kabupaten,
    propinsi: payload.propinsi,
    no_telpon: payload.no_telpon,
    no_wa: payload.no_wa,
    agama: payload.agama, //kalau propesti nya di ganti engga dapet mas kayaknya itu ngambil dari api
    kewarganegaraan: payload.kewarganegaraan,
    // golongan_darah: payload.golongan_darah,
    golongan_darah:payload.golongan_darah[0] === undefined ? "" : payload.golongan_darah[0],
    pendidikan_terakhir: payload.pendidikan_terakhir,
    status_perkawinan: payload.status_perkawinan,
    bahasa_yg_dikuasai: payload.bahasa_yg_dikuasai,
    penyakit_yg_diderita:payload.penyakit_yg_diderita,
    kelainan_fisik: payload.kelainan_fisik,
    status_kepemilikan_rumah: payload.status_kepemilikan_rumah,
    no_ktp: payload.no_ktp,
    no_kk: payload.no_kk,
    no_npwp: payload.no_npwp,
    bank: payload.bank,
    no_rek_bank: payload.no_rek_bank,
    bpjs_kesehatan_status:payload.bpjs_kesehatan_status,
    bpjs_kesehatan_no:payload.bpjs_kesehatan_no,
    bpjs_ketenagakerjaan_status:payload.bpjs_ketenagakerjaan_status,
    bpjs_ketenagakerjaan_no:payload.bpjs_ketenagakerjaan_no,
    tanggal_mulai_bekerja: payload.tanggal_mulai_bekerja,
    masa_kerja_saat_ini: payload.masa_kerja_saat_ini,
    tgl_keluar_pensiun: payload.tgl_keluar_pensiun,
    keterangan_keluar_pensiun:payload.keterangan_keluar_pensiun,
    unit_kerja: payload.unitkerja,
    status_kepegawaian : payload.status_kepegawaian,
    jabatan: payload.jabatan,
    jabatan_tugas_tambahan:payload.jabatan_tugas_tambahan,
    jabatan_tugas_tambahan_2:payload.jabatan_tugas_tambahan_2,
    jabatan_tugas_tambahan_3:payload.jabatan_tugas_tambahan_3,
    golongan:payload.golongan,
    no_yadapen:payload.no_yadapen,
    aktif_non_aktif : payload.aktif_non_aktif,
    apd:payload.apd,
    // region: payload.region,
    status_kependidikan: payload.status_kependidikan,

    pendidikan_formal: map(payload.pendidikan_formal, list => ({
      nip:list.nip,
      // institusi: list.institusi,
      level:list.level,
      nama_sekolah:list.nama_sekolah,
      kota: list.kota,
      jurusan: list.jurusan,
      dari_tahun: list.dari_tahun,
      sampai_tahun: list.sampai_tahun,
    })),

    pendidikan_non_formal:  map(payload.pendidikan_non_formal, list => ({
      nip:list.nip,
      // institusi: list.institusi,
      nama_training:list.nama_training,
      lembaga: list.lembaga,
      kota: list.kota,
      tahun: list.tahun,
      lama: list.lama,
    })),
        
    pekerjaan_history:  map(payload.pekerjaan_history, list => ({
      perusahaan :list.perusahaan,
      kota       :list.kota,
      jabatan    :list.jabatan,
      tahun      :list.tahun,
      sampai_tahun  :list.sampai_tahun,
      keterangan    :list.keterangan,
  })),

    memorandum:  map(payload.memorandum, list => ({
      // nip:list.nip,
      level: list.level,
      no_surat :list.no_surat,
      tanggal : list.tanggal,
      jenis_pelanggaran: list.jenis_pelanggaran,
      keterangan: list.keterangan,
    })),
    
    cuti_panjang:  map(payload.cuti_panjang, list => ({
      alasan_cuti: list.alasan_cuti,
      tanggal:list.tanggal,
      sampai_tanggal:list.sampai_tanggal,
      keterangan: list.keterangan,
    })),

    keluarga:  map(payload.keluarga, list => ({
      id_staff_family:list.id_staff_family,
      nama_lengkap: list.nama_lengkap,
      tanggal_lahir: list.tanggal_lahir,
      agama:list.agama,
      pendidikan: list.pendidikan,
      status: list.status,
      tertanggung:list.tertanggung,
  })),
  };
}

export function managePayrollFormat(payload, isEdit) {
  return {
    ...(isEdit ? { nip: payload.nip } : {}),
    nip:payload.nip,
    id_payroll:payload.id_payroll,
    pph21:payload.pph21,
    honor_tidak_rutin:payload.honor_tidak_rutin,
    potongan_yayasan:payload.potongan_yayasan,
    potongan_luar_yayasan:payload.potongan_luar_yayasan,
  };
}

export function managePotonganFormat(payload, isEdit) {
  console.log(payload)
  return {
    ...(isEdit ? { nip: payload.nip } : {}),
    nip:payload.nip,
    id_payroll:''+payload.id_payroll,
    pph21:payload.pph21,
    angsuran:payload.angsuran,
    titipan:payload.titipan,
    lain_pot_ypl:payload.lain_pot_ypl,
    potongan_cu:payload.potongan_cu,
    sosial:payload.sosial,
    lain_pot_sekolah:payload.lain_pot_sekolah,
  };
}
