import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';
import { Input, InputStaff, Button, Select } from '../../../components/base';

export default class DataKeluarga extends Component{
    constructor(props){
        super(props);
        this._onAddList = this._onAddList.bind(this);
        this._onDeleteListKeluarga = this._onDeleteListKeluarga.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
    }

    _onAddList() {
        const { onAddList, fieldName } = this.props;
        onAddList(fieldName);
    }

    _onDeleteListKeluarga(idx) {
        const { onDeleteListKeluarga, fieldName } = this.props;
        //console.log(fieldName)
        onDeleteListKeluarga(idx, fieldName);
      }  

    _onFormChange(event) {
        const { onChange } = this.props;
        onChange(event);
    }
    
    render() {
        const {
          list, error, onChange,
          fieldName, keluarga_name_agama, keluarga_name_pendidikan ,keluarga_name_tertanggung
        } = this.props;

        return (
           <table className="manage-staff__form-table">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Nama Anggota Keluarga</th>
                  <th>Tanggal Lahir</th>
                  <th>Agama</th>
                  <th>Pendidikan</th>
                  <th>Status</th>
                  <th>Tunjangan Keluarga</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
              {
                map(list, (data, idx) => (
              <tr key={`data_pendidikan_form__${idx}`}>
                <td>{idx + 1}</td>
                <td className="nama_lengkap">
                  <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='keluarga'
                    data-input-type="text"
                    name="nama_lengkap"
                    onChange={onChange}
                    value={data.nama_lengkap}
                  />
                </td>
                <td className="tanggal_lahir">
                  <InputStaff
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='keluarga'
                    
                    type="date"
                    name="tanggal_lahir"
                    onChange={onChange}
                    value={data.tanggal_lahir}
                  />
                </td>
                <td>
                  <Select
                    value={data.agama}
                    placeholder="Pilih Agama"
                    data={keluarga_name_agama}
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name='keluarga'
                    name='agama'
                    onChange={this._onFormChange}
                  />
                </td>
                <td>
                  <Select
                    value={data.pendidikan}
                    placeholder="Pilih Pendidikan"
                    data={keluarga_name_pendidikan}
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name='keluarga'
                    name='pendidikan'
                    onChange={this._onFormChange}
                  />
                </td>
                <td className="status">
                  <Input
                    noMargin
                    data-input-array
                    data-array-position={idx}
                    data-field-name='keluarga'
                    data-input-type="text"
                    name="status"
                    onChange={onChange}
                    value={data.status}
                  />
                </td>
                <td>
                  <Select
                    value={data.tertanggung}
                    placeholder="Pilih Tertanggung"
                    data={keluarga_name_tertanggung}
                    data-input-array
                    arrayPosition={idx}
                    data-array-position={idx}
                    data-field-name='keluarga'
                    name='tertanggung'
                    onChange={this._onFormChange}
                  />
                </td>
                
                <td>
                  <Button onClick={() => this._onDeleteListKeluarga(idx)} icon="icon-trash-o" />
                </td>
              </tr>
            ))
        }
        <tr className="manage-rapbs-plafon__add-more">
            <td colSpan={8}>
              <div>
                <Button
                  icon="icon-plus"
                  onClick={this._onAddList}
                />
              </div>
            </td>
          </tr>
        </tbody>
        </table>
        );
    }
}

DataKeluarga.propTypes = {
    list: PropTypes.array.isRequired,
    error: PropTypes.array.isRequired,
    onAddList: PropTypes.func,
    onDeleteListKeluarga: PropTypes.func,
    onChange: PropTypes.func,
    keluarga_name: PropTypes.array,
};

DataKeluarga.defaultProps = {
    onAddList: noop,
    onDeleteListKeluarga: noop,
    onChange: noop,
    keluarga_name:[],
};