import fixturesConfig from "./fixtures.config";

const defaults = {
  ENV: "prod",
  MOCKAPI: false,
  //URL: "https://dev.api.kliksekolah.com/api",
  URL: "https://api.kliksekolah.com/api",
  URL_2: "https://staff.kliksekolah.com/api",
  //URL_2: "https://staff.demo.kliksekolah.com/api",
  
  URL_3: "https://tax.kliksekolah.com/api",
  fixtures: fixturesConfig,
};
const setEnv = (envKey, value) => {
  defaults[envKey] = value;
  return defaults;
};

export default {
  setEnv,
  ENV: defaults.ENV,
  MOCKAPI: defaults.MOCKAPI,
  URL: defaults.URL,
  URL_2: defaults.URL_2,
  URL_3: defaults.URL_3,
  fixtures: defaults.fixtures,
  PASSWORD: defaults.password,
};

