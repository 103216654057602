import { isNaN, isNumber, map, forEach, result, sumBy } from "lodash";
import moment from "moment";

export function filterObjectProperties(sourceObject = {}, keys = []) {
  const filtered = {};
  keys.forEach((eachKey) => {
    filtered[eachKey] = sourceObject[eachKey];
  });
  return filtered;
}

export function normalizeAmount(amount) {
  if (!amount) {
    return 0;
  }
  amount = amount.toString().replace(/[.,]/g, "");
  if (isNaN(Number(amount, 0))) {
    return 0;
  }
  return Number(amount, 0);

  return amount;
}

export function commaFormatted(amount) {
  const formatted = Number(Math.abs(amount)).toLocaleString("id", {
    maximumFractionDigits: 0,
  });

  if (isNaN(formatted)) {
    return "-";
  }

  if (amount < 0) {
    return `(${formatted})`;
  }
  return formatted;
}

export function generateYearList(date = new Date()) {
  const year = date.getUTCFullYear();
  return [
    { label: year + 1, value: year + 1 },
    { label: year + 2, value: year + 2 },
    { label: year + 3, value: year + 3 },
    { label: year + 4, value: year + 4 },
    { label: year + 5, value: year + 5 },
    { label: year + 6, value: year + 6 },
    { label: year + 7, value: year + 7 },
    { label: year + 8, value: year + 8 },
    { label: year + 9, value: year + 9 },
    { label: year + 10, value: year + 10 },
  ];
}

export function dateFormat(date = new Date(), format = "YYYY-MM-DD") {
  return moment(date).format(format);
}

export function datetimeFormat(date = new Date(), format = "YYYY-MM-DD HH:MM") {
  return moment(date).format(format);
}

export function formatData(data, field) {
  if (!data) {
    return "-";
  }
  const fieldData = result(data, field.attribute, "-");
  const month = [
    "Januari",
    "Febuari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "Novemver",
    "Desember",
  ];
  if (field.type === "date") {
    return dateFormat(fieldData, "DD-MM-YYYY");
  }
  if (field.type === "month_name") {
    return month[parseInt(fieldData)-1];
  }
  if (field.type === "number") {
    return commaFormatted(fieldData);
  }
  if (field.type === "status") {
    if (data.submitted === 0) {
      return "Pending";
    }
    if (data.submitted === 1 && data.approved === 0) {
      return "Submitted";
    }
    if (data.submitted === 1 && data.approved === 1) {
      return "Approved";
    }
  }
  return fieldData;
}

export function generateInvoice(additionalString = "", date = new Date()) {
  return `krj-${additionalString}-${dateFormat(date, "YYYYMMDDHHmmss")}`;
}

export function selectFormat(data, label = "name", value = "_id") {
  return map(data, (list) => ({
    label: result(list, label, ""),
    value: result(list, value, ""),
  }));
}

export function replaceStringAt(string, index, replace) {
  return string.substring(0, index) + replace + string.substring(index + 1);
}

export function dateString(date) {
  return moment(date).hour(0).minute(0).second(0).format();
}

export function editFormFormat(formFields, val) {
  const initialState = {};
  initialState._id = val._id;
  forEach(formFields, (field) => {
    switch (field.inputType) {
      case "number": {
        initialState[field.name] = commaFormatted(val[field.name]);
        break;
      }
      case "object": {
        initialState[field.name] = {
          value: val[field.name]._id,
          label: val[field.name].name,
        };
        break;
      }
      case "date": {
        initialState[field.name] = dateFormat(
          val[field.name].time,
          "YYYY-MM-DD"
        );
        break;
      }
      default: {
        initialState[field.name] = val[field.name];
        break;
      }
    }
  });
  return initialState;
}

export function sumTransactionAmount(transactions) {
  return commaFormatted(
    sumBy(transactions, (transaction) => Number(transaction.amount))
  );
}

export function sumExpectedSemesterAmount(data = {}) {
  const { quantity = 0, price = 0, term = 0 } = data;
  return commaFormatted(Number(quantity) * Number(price) * Number(term));
}

export function sumActualSemesterAmount(data = {}) {
  const { ypl = 0, committee = 0, intern = 0, bos = 0 } = data;
  return commaFormatted(
    Number(ypl) + Number(committee) + Number(intern) + Number(bos)
  );
}

export function calculateAllSemester(semesterOne = [], semesterTwo = []) {
  let ypl = 0;
  let committee = 0;
  let intern = 0;
  let bos = 0;
  let total = 0;

  forEach([...semesterOne, ...semesterTwo], (data) => {
    ypl += Number(data.ypl);
    committee += Number(data.committee);
    intern += Number(data.intern);
    bos += Number(data.bos);
    total += Number(data.quantity) * Number(data.price) * Number(data.term);
  });
  return {
    ypl: isNaN(ypl) ? 0 : commaFormatted(ypl),
    committee: isNaN(committee) ? 0 : commaFormatted(committee),
    intern: isNaN(intern) ? 0 : commaFormatted(intern),
    bos: isNaN(bos) ? 0 : commaFormatted(bos),
    total: isNaN(total) ? 0 : commaFormatted(total),
  };
}

export function editBudgetTransfer(data) {
  return {
    id: data.id,
    description: data.description,
    rapbuSource: map(data.budget_relocation_recipients, (list) => ({
      id: list.id,
      code: {
        code: list.budget_detail_draft.parameter_code.code,
        title: list.budget_detail_draft.parameter_code.title,
        type: list.budget_detail_draft.parameter_code.type,
      },
      title: list.budget_detail_draft.title,
      semester: list.budget_detail_draft.semester,
      quantity: commaFormatted(list.budget_detail_draft.quantity),
      price: commaFormatted(list.budget_detail_draft.price),
      term: commaFormatted(list.budget_detail_draft.term),
      desc: list.budget_detail_draft.desc,
    })),
    periode: "2020",
    head: data.head,
    selectedBudget: map(data.budget_relocation_sources, (list) => ({
      amount: commaFormatted(list.relocated_amount),
      ...list.budget_detail,
    })),
    code_of_account: {
      code: data.account,
      title: "",
      type: "",
    },
  };
}

export function sumBudgetTransferSource(data = []) {
  return sumBy(
    data,
    (list) =>
      normalizeAmount(list.quantity) *
      normalizeAmount(list.price) *
      normalizeAmount(list.term)
  );
}

export function sumByAttribute(data, attr = "") {
  return sumBy(data, (list) => normalizeAmount(list[attr]));
}

export function manageStaffFormState(payload) {
  // console.log(payload)
  // console.log("payload")
  let data_unit_kerja = payload.unit_kerja
    ? payload.unit_kerja.id_unit_kerja
    : [];
  let data_aktif_non_aktif = payload.aktif_non_aktif
    ? payload.aktif_non_aktif.id_aktif_non_aktif
    : [];
  let data_status_perkawinan = payload.status_perkawinan
    ? payload.status_perkawinan.id_ptkp
    : [];
  let data_golongan_darah = payload.golongan_darah
    ? payload.golongan_darah.id_golongan_darah
    : [];
  let data_golongan = payload.golongan ? payload.golongan.id_golongan : [];
  let data_jabatan = payload.jabatan ? payload.jabatan.id_jabatan : [];
  let data_agama = payload.agama ? payload.agama.id_agama : [];

  var today = new Date();
  var kerja = new Date(payload.tanggal_mulai_bekerja);

  let tahun_kerja = today.getFullYear() - kerja.getFullYear();
  let bulan_kerja = today.getMonth() - kerja.getMonth();

  let masa_kerja = Math.floor( (tahun_kerja * 12 + bulan_kerja) / 12 );

  // console.log(payload)
  return {
    form: {
      value: {
        id: payload.id  == undefined ? "" : payload.id ,
        nip: payload.nip == undefined ? "" : payload.nip,
        no_g: payload.no_g == undefined ? "" : payload.no_g,
        nama_lengkap: payload.nama_lengkap == undefined ? "" : payload.nama_lengkap,
        nama_panggilan: payload.nama_panggilan == undefined ? "" : payload.nama_panggilan,
        email: payload.email == undefined ? "" : payload.email,
        tempat_lahir: payload.tempat_lahir == undefined ? "" : payload.tempat_lahir,
        tanggal_lahir: payload.tanggal_lahir == undefined ? "" : payload.tanggal_lahir,
        id_absensi_kartu_fingerprint: payload.id_absensi_kartu_fingerprint  == undefined ? "" : payload.id_absensi_kartu_fingerprint,
        jenis_kelamin: payload.jenis_kelamin == undefined ? "" : payload.jenis_kelamin.id_gender,
        view_jenis_kelamin: payload.jenis_kelamin == undefined ? "" : payload.jenis_kelamin.name,
        tinggi: payload.tinggi == undefined ? "" : payload.tinggi,
        berat: payload.berat == undefined ? "" : payload.berat,
        alamat_tempat_tinggal: payload.alamat_tempat_tinggal == undefined ? "" : payload.alamat_tempat_tinggal,
        rt: payload.rt == undefined ? "" : payload.rt,
        rw: payload.rw == undefined ? "" : payload.rw,
        kelurahan: payload.kelurahan == undefined ? "" : payload.kelurahan,
        kecamatan: payload.kecamatan == undefined ? "" : payload.kecamatan,
        kotamadya_kabupaten: payload.kotamadya_kabupaten == undefined ? "" : payload.kotamadya_kabupaten,
        propinsi: payload.propinsi == undefined ? "" : payload.propinsi,
        no_wa: payload.no_wa == undefined ? "" : payload.no_wa,
        no_telpon: payload.no_telpon == undefined ? "" : payload.no_telpon,
        agama: data_agama == undefined ? "" : data_agama,
        view_agama: payload.agama == undefined ? "" : payload.agama.name,
        // agama: payload.agama.id_agama,
        kewarganegaraan: payload.kewarganegaraan == undefined ? "" : payload.kewarganegaraan,
        golongan_darah: data_golongan_darah == undefined ? "" : data_golongan_darah,
        // golongan_darah: payload.golongan_darah.id_golongan_darah,
        pendidikan_terakhir: payload.pendidikan_terakhir == undefined ? "" : payload.pendidikan_terakhir,
        status_perkawinan: data_status_perkawinan == undefined ? "" : data_status_perkawinan,
        view_status_perkawinan: payload.status_perkawinan == undefined ? "" : payload.status_perkawinan.keterangan,
        //status_perkawinan: payload.status_perkawinan.id_ptkp,
        bahasa_yg_dikuasai: payload.bahasa_yg_dikuasai == undefined ? "" : payload.bahasa_yg_dikuasai,
        penyakit_yg_diderita: payload.penyakit_yg_diderita == undefined ? "" : payload.penyakit_yg_diderita,
        kelainan_fisik: payload.kelainan_fisik == undefined ? "" : payload.kelainan_fisik,
        status_kepemilikan_rumah: payload.status_kepemilikan_rumah == undefined ? "" : payload.status_kepemilikan_rumah,
        no_ktp: payload.no_ktp == undefined ? "" : payload.no_ktp,
        no_kk: payload.no_kk == undefined ? "" : payload.no_kk,
        no_npwp: payload.no_npwp == undefined ? "" : payload.no_npwp,
        bank: payload.bank == undefined ? "" : payload.bank,
        no_rek_bank: payload.no_rek_bank == undefined ? "" : payload.no_rek_bank,
        bpjs_kesehatan_status: payload.bpjs_kesehatan_status == undefined ? "" : payload.bpjs_kesehatan_status,
        bpjs_kesehatan_no: payload.bpjs_kesehatan_no == undefined ? "" : payload.bpjs_kesehatan_no,
        bpjs_ketenagakerjaan_status: payload.bpjs_ketenagakerjaan_status == undefined ? "" : payload.bpjs_ketenagakerjaan_status,
        bpjs_ketenagakerjaan_no: payload.bpjs_ketenagakerjaan_no == undefined ? "" : payload.bpjs_ketenagakerjaan_no,
        tanggal_mulai_bekerja: payload.tanggal_mulai_bekerja == undefined ? "" : payload.tanggal_mulai_bekerja,
        masa_kerja_saat_ini: masa_kerja == undefined ? "" : masa_kerja,
        tgl_keluar_pensiun: payload.tgl_keluar_pensiun == undefined ? "" : payload.tgl_keluar_pensiun,
        keterangan_keluar_pensiun: payload.keterangan_keluar_pensiun == undefined ? "" : payload.keterangan_keluar_pensiun,
        unitkerja: data_unit_kerja == undefined ? "" : data_unit_kerja,
        view_unitkerja: payload.unit_kerja == undefined ? "" : payload.unit_kerja.name,
        // unitkerja: payload.unit_kerja.id_unit_kerja,
        status_kepegawaian: payload.status_kepegawaian == null ? "" : payload.status_kepegawaian.id_status_kepegawaian,
        view_status_kepegawaian: payload.status_kepegawaian == null ? "" : payload.status_kepegawaian.name,
        jabatan_tugas_tambahan: payload.jabatan_tugas_tambahan == undefined ? "" : payload.jabatan_tugas_tambahan,
        jabatan_tugas_tambahan_2: payload.jabatan_tugas_tambahan_2 == undefined ? "" : payload.jabatan_tugas_tambahan_2,
        jabatan_tugas_tambahan_3: payload.jabatan_tugas_tambahan_3 == undefined ? "" : payload.jabatan_tugas_tambahan_3,
        jabatan: data_jabatan == undefined ? "" : data_jabatan,
        view_jabatan: payload.jabatan == undefined ? "" : payload.jabatan.name,
        view_jabatan_tugas_tambahan: payload.array_jabatan_tugas_tambahan == undefined ? "" : payload.array_jabatan_tugas_tambahan.name,
        view_jabatan_tugas_tambahan_2: payload.array_jabatan_tugas_tambahan_2 == undefined ? "" : payload.array_jabatan_tugas_tambahan_2.name,
        view_jabatan_tugas_tambahan_3: payload.array_jabatan_tugas_tambahan_3 == undefined ? "" : payload.array_jabatan_tugas_tambahan_3.name,
        // jabatan: payload.jabatan,
        golongan: data_golongan == undefined ? "" : data_golongan,
        // golongan:payload.golongan.id_golongan,
        no_yadapen: payload.no_yadapen == undefined ? "" : payload.no_yadapen,
        aktif_non_aktif: data_aktif_non_aktif == undefined ? "" : data_aktif_non_aktif,
        view_aktif_non_aktif : payload.aktif_non_aktif == undefined ? "" : payload.aktif_non_aktif.name,
        apd: payload.apd == undefined ? "" : payload.apd.id_jenjang_pendidikan,
        view_apd: payload.apd == undefined ? "" : payload.apd.name,
        status_kependidikan: payload.status_kependidikan == undefined ? "" : payload.status_kependidikan.id_status_kependidikan,
        view_status_kependidikan: payload.status_kependidikan == undefined ? "" : payload.status_kependidikan.name,
        // region: payload.region == undefined ? "" : payload.region,

        pendidikan_formal:payload.pendidikan_formal== undefined?"": map(payload.pendidikan_formal, (list) => ({
          // id_staff_edu_formal:list.id_staff_edu_formal,
          level: list.level,
          // pendidikan: list.pendidikan,
          nama_sekolah: list.nama_sekolah,
          kota: list.kota,
          jurusan: list.jurusan,
          dari_tahun: list.dari_tahun,
          sampai_tahun: list.sampai_tahun,
        })),

        pendidikan_non_formal:payload.pendidikan_non_formal == undefined?"": map(payload.pendidikan_non_formal, (list) => ({
          // institusi: list.institusi,
          nama_training: list.nama_training,
          lembaga: list.lembaga,
          kota: list.kota,
          tahun: list.tahun,
          lama: list.lama,
          //nim: list.nim,
        })),

        pekerjaan_history:payload.pekerjaan_history == undefined?"": map(payload.pekerjaan_history, (list) => ({
          perusahaan: list.perusahaan,
          kota: list.kota,
          jabatan: list.jabatan,
          tahun: list.tahun,
          sampai_tahun: list.sampai_tahun,
          keterangan: list.keterangan,
        })),

        memorandum:payload.memorandum == undefined?"": map(payload.memorandum, (list) => ({
          // nip:list.nip,
          level: list.level,
          tanggal: list.tanggal,
          no_surat: list.no_surat,
          jenis_pelanggaran: list.jenis_pelanggaran,
          keterangan: list.keterangan,
        })),

        cuti_panjang:payload.cuti_panjang == undefined?"": map(payload.cuti_panjang, (list) => ({
          alasan_cuti: list.alasan_cuti,
          tanggal: list.tanggal,
          sampai_tanggal: list.sampai_tanggal,
          keterangan: list.keterangan,
        })),

        keluarga:payload.keluarga == undefined?"": map(payload.keluarga, (list) => ({
          nip: list.nip,
          nama_lengkap: list.nama_lengkap,
          tanggal_lahir: list.tanggal_lahir,
          agama: list.agama,
          pendidikan: list.pendidikan,
          status: list.status,
          tertanggung: list.tertanggung,
        })),
      },

      error: {
        pendidikan_formal: map(payload.pendidikan_formal, () => ({
          id_staff_edu_formal: "",
          level: "",
          pendidikan: "",
          nama_sekolah: "",
          kota: "",
          jurusan: "",
          dari_tahun: "",
          sampai_tahun: "",
        })),

        pendidikan_non_formal: map(payload.pendidikan_non_formal, () => ({
          institusi: "",
          nama_training: "",
          kota: "",
          //nim: '',
          lembaga: "",
          tahun: "",
          lama: "",
        })),
        pekerjaan_history: map(payload.pekerjaan_history, () => ({
          perusahaan: "",
          kota: "",
          jabatan: "",
          tahun: "",
          sampai_tahun: "",
          keterangan: "",
        })),

        memorandum: map(payload.memorandum, () => ({
          level: "",
          tanggal: "",
          no_surat: "",
          jenis_pelanggaran: "",
          keterangan: "",
        })),
        cuti_panjang: map(payload.cuti_panjang, () => ({
          alasan_cuti: "",
          tanggal: "",
          sampai_tanggal: "",
          keterangan: "",
        })),
        keluarga: map(payload.keluarga, () => ({
          // id_staff_family: "",
          nama_lengkap: "",
          tanggal_lahir: "",
          agama: "",
          pendidikan: "",
          status: "",
          tertanggung: "",
        })),
      },
    },
  };
}
