import React, { Component } from "react";
import PropTypes from "prop-types";
import { map, noop, isEmpty, isEqual } from "lodash";
import { Input, SearchSelect } from "../base";
import { streamURL } from "../../utils/stream.util";
import Breadcrumb from "../Breadcrumb/Breadcrumb.component";
import Notifications from "../Notifications/Notifications.component";
import logo from "../../assets/img/kliksekolah.png";
import yplLogo from "../../assets/img/Spin.png";
import { HEADER_ICON } from "../../variables/common.variable";
import language from "../../languages";

class HeaderComponent extends Component {
  constructor(props) {
    super(props);

    this.onClickUnit = this.onClickUnit.bind(this);
    this.startEventSource = this.startEventSource.bind(this);
    this.onClickHeaderIcon = this.onClickHeaderIcon.bind(this);
    this.getNotifications = this.getNotifications.bind(this);
    this.unitSelectorGroup = [
      "Bendahara",
      "Personalia",
      "Korektor Perwakilan",
      "Korektor Pusat",
      "Manager Keuangan",
    ];
    this.state = {
      notifications: [],
      iconState: {},
      notificationState: {},
    };
    this.streamInterval = null;
  }

  componentDidMount() {
    this.getNotifications();
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if(
  //     !isEqual(prevState.notifications, this.state.notifications) ||
  //     !isEqual(prevState.iconState, this.state.iconState)) {
  //     this.setState(prevState => ({
  //       ...prevState,
  //       iconState: {
  //         ...prevState.iconState,
  //         notifications: true,
  //       }
  //     }));
  //   }
  //
  // }
  //
  componentWillUnmount() {
    clearInterval(this.streamInterval);
  }

  shouldComponentUpdate(nextProps = {}, nextState = {}) {
    if (
      !isEqual(nextState.notifications, this.state.notifications) ||
      !isEqual(nextState.iconState, this.state.iconState) ||
      !isEqual(nextProps.user.workingUnit, this.props.user.workingUnit)
    ) {
      return true;
    }

    return false;
  }

  async getNotifications() {
    const { handleGetNotifications, user } = this.props;
    if (user) {
      const data = await handleGetNotifications();
      const { notifications } = this.state;
      if (!isEqual(notifications, data)) {
        this.setState(
          (prevState) => ({
            ...prevState,
            notifications: data,
            iconState: {
              notifications: true,
            },
          }),
          () => {
            clearInterval(this.streamInterval);
            this.startEventSource();
          }
        );
      }
    }
  }

  startEventSource() {
    this.streamInterval = setInterval(() => {
      this.getNotifications();
    }, 10000);
  }

  onClickUnit(event) {
    const { setWorkingUnit } = this.props;
    const { target } = event;

    setWorkingUnit(target.value);
  }

  onClickHeaderIcon(context) {
    this.setState((prevState) => ({
      ...prevState,
      iconState: {
        ...prevState.iconState,
        [context]: !prevState.iconState[context],
      },
      notificationState: {
        ...prevState.notificationState,
        [context]: !prevState.notificationState[context],
      },
    }));
  }

  render() {
    const {
      onClickMenu,
      pathname,
      onClickBreadcrumb,
      user,
      onSearchUnit,
      history,
      updateNotificationStatus,
      handleAcceptReconciliation,
    } = this.props;
    const { notifications, iconState, notificationState } = this.state;
    const { school_unit, user_group, workingUnit } = user;
    let userGroupName = user_group.name;
    let unitCode = "";
    let unitId = null;
    let canSelectUnit = false;
    let defaultValue = {
      id: "0",
      title: "Semua Unit",
    };

    if (workingUnit && workingUnit.attributes) {
      unitCode = workingUnit.attributes.unit_code;
      defaultValue = {
        id: workingUnit.attributes.id,
        title: workingUnit.attributes.name,
      };
    } else if (school_unit && school_unit.unit_code) {
      unitCode = school_unit.unit_code;
      defaultValue = {
        id: school_unit.id,
        title: school_unit.name,
      };
    }
    if (
      user_group.name === "Manager Keuangan" ||
      user_group.name === "Bendahara" ||
      user_group.name === "Korektor Pusat" ||
      user_group.name === "Korektor Perwakilan" ||
      user_group.name === "Ketua Perwakilan" ||
      user_group.name === "Admin Keuangan" ||
      user_group.name === "Personalia"
    ) {
      canSelectUnit = true;
    }

    return (
      <header className="header">
        <div className="header__top">
          <div className="header__top-left">
            <img className="header__logo" src={logo} alt="logo" />
            <div className="header__title">
              <p>KODE UNIT:</p>
              <p>{unitCode}</p>
            </div>
          </div>
          <div className="header__top-right">
            <form className="header__search-form">
              <Input
                noMargin
                theme="line"
                leftIcon="icon-search"
                placeholder="Search here.."
                value=""
              />
            </form>

            <button
              className="header__icon"
              onClick={() => this.onClickHeaderIcon("download")}
            >
              <div className={`pulsar ${iconState.download ? "" : "hidden"}`}>
                <div className="ring" />
              </div>
              <span className="icon-download" />
            </button>
            <button
              className="header__icon"
              onClick={() => this.onClickHeaderIcon("messaging")}
            >
              <div className={`pulsar ${iconState.messaging ? "" : "hidden"}`}>
                <div className="ring" />
              </div>
              <span className="icon-comments" />
            </button>
            <button
              className="header__icon"
              onClick={() => this.onClickHeaderIcon("email")}
            >
              <div className={`pulsar ${iconState.email ? "" : "hidden"}`}>
                <div className="ring" />
              </div>
              <span className="icon-envelope" />
            </button>
            <div className="header__icon">
              <div
                className={`pulsar ${iconState.notifications ? "" : "hidden"}`}
              >
                <div className="ring" />
              </div>
              <span
                className="icon-bell"
                onClick={() => this.onClickHeaderIcon("notifications")}
              />
              <Notifications
                data={notifications}
                history={history}
                visible={notificationState.notifications}
                handleAcceptReconciliation={handleAcceptReconciliation}
                updateNotificationStatus={updateNotificationStatus}
              />
            </div>
            <button
              className="header__icon"
              onClick={() => this.onClickHeaderIcon("refresh")}
            >
              <div className={`pulsar ${iconState.refresh ? "" : "hidden"}`}>
                <div className="ring" />
              </div>
              <span className="icon-repeat" />
            </button>
            <button onClick={onClickMenu}>
              <img className="header__ypl-logo" src={yplLogo} alt="YPL Logo" />
            </button>
          </div>
        </div>
        <div className="header__bottom">
          <div className="header__top-left" />
          <div className="header__bottom-right">
            <p className="header__greeting">
              {language.translate.COMMON__WELCOME} {user.name}
            </p>
            <Breadcrumb pathname={pathname} onClick={onClickBreadcrumb} />
            {canSelectUnit && (
              <div className="unit_selector">
                <SearchSelect
                  async
                  onSearch={onSearchUnit}
                  onChange={this.onClickUnit}
                  labelName="title"
                  valueName="id"
                  placeholder="Pilih Unit Kerja"
                  value={user.workingUnit || defaultValue}
                />
              </div>
            )}
          </div>
        </div>
      </header>
    );
  }
}

export default function Header(props) {
  return <HeaderComponent {...props} />;
}

HeaderComponent.propTypes = {
  onClickMenu: PropTypes.func,
  onClickBreadcrumb: PropTypes.func,
  onSearchUnit: PropTypes.func,
  setWorkingUnit: PropTypes.func,
  pathname: PropTypes.string.isRequired,
  user: PropTypes.object,
  notifications: PropTypes.object,
  handleGetNotifications: PropTypes.func,
  handleAcceptReconciliation: PropTypes.func,
  updateNotificationStatus: PropTypes.func,
  history: PropTypes.object,
};

HeaderComponent.defaultProps = {
  onClickMenu: noop,
  onClickBreadcrumb: noop,
  onSearchUnit: noop,
  setWorkingUnit: noop,
  user: null,
  handleGetNotifications: noop,
  handleAcceptReconciliation: noop,
  updateNotificationStatus: PropTypes.func,
  notifications: {},
  history: {},
};
